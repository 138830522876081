<template>
  <div
    v-if="view"
    class="text-truncate"
    v-text="sSelected ? sSelected.text : ''"
  />
  <ValidationProvider
    v-else
    :name="label ? $t(label) : undefined"
    :rules="required ? 'required' : ''"
    slim
    vid="accountingmovementtype"
  >
    <template #default="{ errors, valid }">
      <v-select
        v-model="sValue"
        :error-messages="errors"
        :items="items"
        :label="label ? $t(label) : undefined"
        :menu-props="{ offsetY: true }"
        :success="valid"
        dense
        flat
        full-width
        hide-details
        outlined
        v-bind="$attrs"
        @change="onSelect"
      />
    </template>
  </ValidationProvider>
</template>

<script lang="ts">
import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import { find } from "lodash";
import { SelectItemData } from "@/types/utils";

@Component
export default class AccountingDescriptionSelect extends Vue {
  @VModel({ type: String }) sValue!: string;
  @Prop(Boolean) readonly view!: boolean;
  @Prop(Boolean) readonly required!: boolean;
  @Prop(String) readonly label!: string;

  get items(): SelectItemData<string>[] {
    return [
      {
        text: this.$t("accounting.desc.invoice"),
        value: "invoice.short_name|invoice.serial|invoice.number|customer.name",
      },
      {
        text: this.$t("accounting.desc.invoice.addenda"),
        value:
          "invoice.short_name|invoice.serial|invoice.number|invoice.addenda",
      },
      {
        text: this.$t("accounting.desc.check"),
        value:
          "paymentmethod.check|paymentmethod.bank|paymentmethod.account_serial|paymentmethod.account_number|customer.name",
      },
      {
        text: this.$t("accounting.desc.check.addenda"),
        value:
          "paymentmethod.check|paymentmethod.bank|paymentmethod.account_serial|paymentmethod.account_number|invoice.addenda",
      },
      {
        text: this.$t("accounting.desc.card"),
        value: "paymentmethod.card.holder|customer.name",
      },
      {
        text: this.$t("accounting.desc.card.addenda"),
        value: "paymentmethod.card.holder|invoice.addenda",
      },
      {
        text: this.$t("accounting.desc.withholding"),
        value: "withholding.code|customer.name",
      },
      {
        text: this.$t("accounting.desc.withholding.addenda"),
        value: "withholding.code|invoice.addenda",
      },
    ];
  }

  get sSelected() {
    return this.sValue ? find(this.items, { value: this.sValue }) : null;
  }

  onSelect(sValue: string) {
    this.$emit("change", sValue);
  }
}
</script>
